import React from 'react';

export default class TeacherHOme extends React.Component {
    render() {
        return (
            <div>
                {' '}
                <h2>Teacher Home </h2> <br />
                Coming Soon
            </div>
        );
    }
}
